<template>
    <div style="min-height:calc(100vh - 90px);">
        <tabs :tabs="tabs" class="border-bottom" />
        <div class="pt-2" style="height:calc(100vh - 140px);">
            <router-view />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    middleware:['auth'],
    data(){
        return {
            tabsReales: {
                admin: [
                    { titulo: 'Pedidos', ruta: 'admin.almacen.pedidos', can:'tab1_abastecimiento_pedidos'},
                    { titulo: 'Entregas', ruta: 'admin.almacen.logistica_despacho', can:'tab1_abastecimiento_entregas'},
                    { titulo: 'Tablero', ruta: 'admin.almacen.tablero', can:'tab1_abastecimiento_tablero'},
                    { titulo: 'Productos', ruta: 'admin.almacen.productos', can:'tab1_abastecimiento_productos'},
                    { titulo: 'Historial', ruta: 'admin.almacen.historial', can:'tab1_abastecimiento_historial'},
                    { titulo: 'Mapa', ruta: 'admin.almacen.pedidos-x-entregar', can:'tab1_abastecimiento_mapa'},
                    { titulo: 'Calificaciones', ruta: 'admin.almacen.calificaciones', can:'tab1_abastecimiento_calificaciones'},
                    { titulo: 'Cancelados', ruta: 'admin.almacen.cancelados',num: 0, can:'tab1_abastecimiento_cancelados'},
                ],
                tendero: [
                    { titulo: 'Historial Pedidos', ruta: 'tendero.almacen.historial-pedidos'},
                    { titulo: 'Pedidos recurrentes', ruta: 'tendero.almacen.pedidos-recurrentes'},
                    { titulo: 'Dashboard', ruta: 'tendero.almacen.dashboard' },
                    { titulo: 'Creditos', ruta: 'tendero.almacen.creditos' },
                ],
            }
        }
    },
    computed: {
        ...mapGetters({
            contadores:'pedidos/pedidos_cancelados_admin/contadores',
            id_cedis: 'cedis/id_cedis'
        }),
        tabs(){
            let tabs = this.tabsReales[this.$usuario.rol_nombre]
            if (this.$usuario.rol_nombre == 'admin'){
                tabs[7].num = this.contadores
            }
            return tabs
        },
    },
    watch: {
        async id_cedis(val){
            await this.$store.dispatch('pedidos/pedidos_cancelados_admin/get_contadores')
        }
    },
    async mounted(){
        await this.$store.dispatch('pedidos/pedidos_cancelados_admin/get_contadores')
    }
}

</script>

<style lang="scss" scoped>
    .box--contorno{
        border: 1px solid #dee2e6;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
</style>
